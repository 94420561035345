<template>
	<div class="bottom-nav">
		<ul class="nav-contents">
			<li @click="navClickListener(0)" :class="[id === 0 ? 'active' : '']">
				<svg
					version="1.1"
					id="레이어_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M50,23.7l20.8,18.8V75h-8.3V50h-25v25h-8.3V42.5L50,23.7z M50,12.5L8.3,50h12.5v33.3h25v-25h8.3v25h25V50h12.5L50,12.5z"
					/>
				</svg>
				<i>홈</i>
			</li>
			<li @click="navClickListener(1)" :class="[id === 1 ? 'active' : '']">
				<svg
					version="1.1"
					id="레이어_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M87.5,50.9C87.5,28,69.8,12.5,50,12.5c-19.5,0-37.5,15.2-37.5,38.7c-2.5,1.4-4.2,4.1-4.2,7.2v8.3c0,4.6,3.8,8.3,8.3,8.3h4.2V49.6c0-16.1,13-29.2,29.2-29.2s29.2,13,29.2,29.2v29.6H45.8v8.3h33.3c4.6,0,8.3-3.8,8.3-8.3v-5.1c2.5-1.3,4.2-3.8,4.2-6.8v-9.6C91.7,54.8,90,52.2,87.5,50.9z"
					/>
					<path
						class="st0"
						d="M37.5,58.3c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2c-2.3,0-4.2,1.9-4.2,4.2C33.3,56.5,35.2,58.3,37.5,58.3z"
					/>
					<path
						class="st0"
						d="M62.5,58.3c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2c-2.3,0-4.2,1.9-4.2,4.2C58.3,56.5,60.2,58.3,62.5,58.3z"
					/>
					<path
						class="st0"
						d="M75,46c-2-11.9-12.3-21-24.8-21C37.6,25,24,35.5,25.1,51.9c10.3-4.2,18-13.4,20.3-24.5C50.8,38.3,62,45.8,75,46z"
					/>
				</svg>
				<i>서비스</i>
			</li>
			<li @click="navClickListener(2)" :class="[id === 2 ? 'active' : '']">
				<svg
					version="1.1"
					id="레이어_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M50,25c4.6,0,8.3,3.8,8.3,8.3c0,4.6-3.8,8.3-8.3,8.3c-4.6,0-8.3-3.8-8.3-8.3C41.7,28.7,45.4,25,50,25z M50,62.5c11.2,0,24.2,5.4,25,8.3V75H25v-4.1C25.8,67.9,38.8,62.5,50,62.5z M50,16.7c-9.2,0-16.7,7.5-16.7,16.7C33.3,42.5,40.8,50,50,50c9.2,0,16.7-7.5,16.7-16.7C66.7,24.1,59.2,16.7,50,16.7z M50,54.2c-11.1,0-33.3,5.6-33.3,16.7v12.5h66.7V70.8C83.3,59.7,61.1,54.2,50,54.2z"
					/>
				</svg>
				<i>MY</i>
			</li>
			<li @click="navClickListener(3)" :class="[id === 3 ? 'active' : '']">
				<svg
					version="1.1"
					id="레이어_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M25,41.7c-4.6,0-8.3,3.8-8.3,8.3c0,4.6,3.8,8.3,8.3,8.3c4.6,0,8.3-3.8,8.3-8.3C33.3,45.4,29.6,41.7,25,41.7zM75,41.7c-4.6,0-8.3,3.8-8.3,8.3c0,4.6,3.8,8.3,8.3,8.3c4.6,0,8.3-3.8,8.3-8.3C83.3,45.4,79.6,41.7,75,41.7z M50,41.7c-4.6,0-8.3,3.8-8.3,8.3c0,4.6,3.8,8.3,8.3,8.3c4.6,0,8.3-3.8,8.3-8.3C58.3,45.4,54.6,41.7,50,41.7z"
					/>
				</svg>
				<i>더보기</i>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	neme: 'Footer',

	props: {
		id: {
			type: Number,
			required: true,
		},
	},

	methods: {
		navClickListener(code) {
			console.debug(code);
			if (this.id !== code) {
				if (code === 0) {
					this.$router.push({ name: 'UserHome' });
				} else if (code === 1) {
					this.$router.push({ name: 'Service' });
				} else if (code === 2) {
					this.$router.push({ name: 'My' });
				} else if (code === 3) {
					this.$router.push({ name: 'More' });
				}
			}
		},
	},
};
</script>
