<template>
	<!-- KP-APP-D-SC-2004 (우편,쇼핑) Code Password Modal Html -->
	<div class="codepass-modal popup-bg on">
		<div class="box-txt">
			<div class="modal-tit">
				<p>인증번호</p>
				<a @click="$emit('close')">
					<img :src="require('@/assets/img/ic_close.png')" alt="닫기" />
				</a>
			</div>
			<ul>
				<li>1</li>
				<li>2</li>
				<li>3</li>
				<li>4</li>
				<li>5</li>
				<li>6</li>
			</ul>
			<span>{{ timeString }}</span>
			<i
				>유효시간 내에 PC에서<br />인증번호를 입력하여 사이트에<br />로그인하세요</i
			>
			<button class="rescan"><a href="#">다시 스캔하기</a></button>
			<div class="box-shadow"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CodePassModal',

	data() {
		return {
			countDown: 120,
			min: 0,
			sec: 0,
			timeString: '',

			timer: null,
		};
	},

	created() {
		this.countDownTimer();
	},

	beforeDestroy() {
		clearTimeout(this.timer);
	},

	methods: {
		countDownTimer() {
			if (this.countDown > 0) {
				this.timer = setTimeout(() => {
					console.debug('asdasd', this.timeString);
					this.countDown -= 1;
					const hours = Math.floor(this.countDown / 3600);
					const minutes = Math.floor((this.countDown - hours * 3600) / 60); // get minutes
					const seconds = this.countDown - hours * 3600 - minutes * 60;

					this.timeString =
						'유효시간: ' +
						String(minutes) +
						'분 ' +
						String(seconds).padStart(2, '0') +
						'초';
					this.countDownTimer();
				}, 1000);
			} else {
				this.$emit('close');
			}
		},
	},
};
</script>
