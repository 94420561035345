<template>
	<div>
		<div class="m54 wrapper">
			<div class="top-nav">
				<div class="icon-txt">
					<a @click="$router.push({ name: 'Alram' })">
						<div class="top-iconClose">
							<img
								:src="require('@/assets/img/ic_notifications.svg')"
								alt="알림"
							/>
						</div>
					</a>
					<p>우체국</p>
				</div>
			</div>
			<div class="top-bg">
				<!-- 개인회원 정보 -->
				<div class="personal-info">
					<a href="#">
						<div class="box-infotxt">
							<p>
								{{ memberInfo.nm }}<br />{{ convertTelNum(memberInfo.mbtlnum) }}
							</p>
							<img
								:src="require('@/assets/img/img_m_home_card.png')"
								alt="카드이미지"
							/>
						</div>
						<div class="detail-info">
							<p>생년월일</p>
							<p>
								{{ memberInfo.brthdy }} / {{ memberInfo.sexdstn }} /
								{{ memberInfo.nltytyNm }}
							</p>
						</div>
					</a>
					<div class="box-shadow"></div>
				</div>
			</div>
			<!-- End of Header Section -->

			<div class="bottom-bg">
				<!-- APP 바로가기 -->
				<div class="go-service">
					<p>우정서비스 바로가기</p>
					<div class="app-container">
						<ul class="box-app">
							<li>
								<div class="app-bg">
									<img
										:src="require('@/assets/img/ic_m_home_01_on.png')"
										alt="우편앱 이미지"
									/>
									<div class="box-shadow app"></div>
								</div>
								<span>우편</span>
							</li>
							<li>
								<div class="app-bg">
									<img
										:src="require('@/assets/img/ic_m_home_02_on.png')"
										alt="쇼핑앱 이미지"
									/>
									<div class="box-shadow app"></div>
								</div>
								<span>쇼핑</span>
							</li>
							<li>
								<div class="app-bg">
									<img
										:src="require('@/assets/img/ic_m_home_04_on.png')"
										alt="PostPay앱 이미지"
									/>
									<div class="box-shadow app"></div>
								</div>
								<span>편드</span>
							</li>
						</ul>
						<ul class="box-app">
							<li>
								<div class="app-bg">
									<img
										:src="require('@/assets/img/ic_m_home_03_on.png')"
										alt="예금앱 이미지"
									/>
									<div class="box-shadow app"></div>
								</div>
								<span>예금</span>
							</li>
							<li>
								<div class="app-bg">
									<img
										:src="require('@/assets/img/ic_m_home_05_on.png')"
										alt="보험앱 이미지"
									/>
									<div class="box-shadow app"></div>
								</div>
								<span>보험</span>
							</li>
						</ul>
					</div>
				</div>

				<!-- Navigation Html Code -->
				<Footer :id="0" />
				<!-- End of Navigation Html code -->

				<!-- 큐알스캔 이미지 -->
				<div class="qr-scan modal-event">
					<a @click="openQRScanner">
						<img
							:src="require('@/assets/img/ic_qr (1).svg')"
							alt="큐알스캔 이미지"
						/>
						<p>QR 스캔</p>
					</a>
				</div>
			</div>
			<!-- End of Bottom bg section -->
		</div>
		<!-- End of m54 wrapper -->

		<CodePassModal v-if="isCodeModalShow" @close="codePassModalCloseListener" />
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CodePassModal from '@/components/modal/CodePassModal';
import Footer from '@/components/Footer';
import { KPIC_dispatchQrReader, KPIC_dispatchDID } from '@/util/appBridge';
import { KPICApi } from '@/api';
import { DEPLOY_MODE } from '@/constants';

const dummyData = {
	RESULT_CD: '1',
	RESULT_MSSAGE: '성공',
	apiKpdidMberIndvdl: {
		mberId: '20211230104824035436',
		indvdlPi:
			'P442e2330dcd81c202c0803a86d3b8893e508f081e5c63804cf973ac7f928e6e1KR',
		kpDid: '111111',
		nltytyCd: '',
		nltytyNm: '',
		nm: '테스트',
		brthdy: '',
		sexdstn: '',
		mbtlnum: '01000000000',
		ci: '',
		di: '',
		email: '',
		zip: '',
		zipadres: '',
		detladres: '',
		zipAdrestyCd: '',
		zipRdnmadr: '',
		zipLnmadres: '',
		fngprtrecogUseatCd: '',
		facerecogUseatCd: '',
		fngprtrecogUseatNm: '',
		ntcnUseatCd: '',
		ntcnUseatNm: '',
		sbscrbDt: '',
		sbscrbDtMk: '',
		sbscrbResn: '',
		secsnDt: '',
		secsnDtMk: '',
		secsnResn: '',
		creatUserSn: '',
		creatUserNm: '',
		creatDt: '20211230104824',
		creatDt8Mk: '10:48',
		creatDt12Mk: '2021-12-30 10:48',
		lastopertUserSn: '',
		lastopertUserNm: '',
		lastopertDt: '20211230104824',
		lastopertDt8Mk: '10:48',
		lastopertDt12Mk: '2021-12-30 10:48',
		prcSttusCd: '1',
		prcSttusNm: '발급',
		prcUserSn: '',
		prcDt: '20211230104824',
		prcDt8Mk: '10:48',
		prcDt12Mk: '2021-12-30 10:48',
		prcResn: '',
		issuUserSn: '',
		issuDt: '20211230104824',
		issuDt8Mk: '',
		issuDt12Mk: '2021-12-30 10:48',
		issuResn: '',
		dsuseUserSn: '',
		dsuseDt: '',
		dsuseDt8Mk: '',
		dsuseDt12Mk: '',
		dsuseResn: '',
	},
};
export default {
	name: 'UserHome',

	components: {
		CodePassModal,
		Footer,
	},

	computed: {
		...mapGetters(['memberInfo']),
	},

	data() {
		return {
			isCodeModalShow: false,
			selectedNavCode: 0,
			dispatchListener: null,

			DID: null,
		};
	},

	methods: {
		...mapMutations({ saveMemberInfo: 'saveMemberInfo' }),

		codePassModalCloseListener() {
			this.isCodeModalShow = false;
		},

		openQRScanner() {
			KPIC_dispatchQrReader();
		},

		convertTelNum(tel) {
			if (tel) {
				return tel.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
			}
			return '';
		},
	},

	created() {
		// KPIC_dispatchCreateDID();
		console.debug('dispatchDIDCallback({RESULT: "OK", DATA: {DID: "111111"}})');
		KPIC_dispatchDID();

		window.doQRScanCallback = res => {
			window.doQRCallback = null;
			console.debug('doQRCallback', res);
			if (res && res.RESULT) {
				if (res.RESULT === 'OK') {
					this.isCodeModalShow = true;
					// 법인회원인지 개인회원인지 분기 처리한다.
				}
			}
		};

		window.dispatchDIDCallback = res => {
			window.dispatchDIDCallback = null;
			console.debug('dispatchDIDCallback', res);
			if (res && res.RESULT) {
				if (res.RESULT === 'OK') {
					// this.DID = res.DATA.DID;
					KPICApi.fetchMemberInfo(res.DATA.DID)
						.then(({ data }) => {
							console.debug(data);
							if (data.RESULT_CD === '1') {
								this.saveMemberInfo(data.apiKpdidMberIndvdl);
							} else {
								alert(data.RESULT_MSSAGE);
							}
						})
						.catch(err => {
							console.debug(err);
							if (DEPLOY_MODE === 'D') {
								if (dummyData.RESULT_CD === '1') {
									this.saveMemberInfo(dummyData.apiKpdidMberIndvdl);
								} else {
									alert(dummyData.RESULT_MSSAGE);
								}
							}
						});
				}
			}
		};
	},
};
</script>
